module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Japanese 101 Resources","short_name":"JPResources","start_url":"/","background_color":"#d92a51","theme_color":"#3b184b","display":"standalone","icon":"src/images/logo/logo.png","icon_options":{"purpose":"maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4b9826fdc10a23c05445a89547883b30"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
