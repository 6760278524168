// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intro-old-tsx": () => import("./../../../src/pages/intro-old.tsx" /* webpackChunkName: "component---src-pages-intro-old-tsx" */),
  "component---src-pages-make-flashcards-tsx": () => import("./../../../src/pages/make-flashcards.tsx" /* webpackChunkName: "component---src-pages-make-flashcards-tsx" */),
  "component---src-pages-my-flashcards-tsx": () => import("./../../../src/pages/my/flashcards.tsx" /* webpackChunkName: "component---src-pages-my-flashcards-tsx" */),
  "component---src-pages-my-lessons-tsx": () => import("./../../../src/pages/my/lessons.tsx" /* webpackChunkName: "component---src-pages-my-lessons-tsx" */),
  "component---src-pages-my-manage-tsx": () => import("./../../../src/pages/my/manage.tsx" /* webpackChunkName: "component---src-pages-my-manage-tsx" */),
  "component---src-pages-my-reviews-tsx": () => import("./../../../src/pages/my/reviews.tsx" /* webpackChunkName: "component---src-pages-my-reviews-tsx" */),
  "component---src-pages-my-tsx": () => import("./../../../src/pages/my.tsx" /* webpackChunkName: "component---src-pages-my-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/content-page.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-deck-page-tsx": () => import("./../../../src/templates/deck-page.tsx" /* webpackChunkName: "component---src-templates-deck-page-tsx" */)
}

